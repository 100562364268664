/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');




      // Mobile navigation
      $('.hamburger').on('click', function(){
        $(this).toggleClass('is-active');
        $('.nav-primary').toggleClass('is-active');
      });





      // Sliders
      $('.slider').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        responsive: [{
            breakpoint: 480,
            settings: {
              arrows: false
            }
          }]
      });


      // Accordion
      $('.accordion .accordion-heading').click(function(){
        $(this).parent().siblings().removeClass('open');
        $(this).parent().toggleClass('open');

        $('html,body').animate({
          scrollTop: $(this).offset().top - 50
        }, 500);
      });

      $('.accordion .accordion-heading > a').click(function(ev){
        ev.stopPropagation();
      });


      // Tabs
      $('.acf-tab-headings .tab-heading:first').addClass('active');
      $('.tab-content:first').addClass('active');
      $('.acf-tab-headings .tab-heading').click(function(){
        $(this).siblings().removeClass('active');
        $('.tab-content').removeClass('active');
        $(this).addClass('active');
        var id = $(this).attr('data-content');
        $('*[data-tab="'+id+'"]').addClass('active');
        window.location.hash = id;
        // Triggle google map re-render. Maps won't render if original div is hidden
        for (i = 0; i < maps.length; i++) {
          google.maps.event.trigger(maps[i], 'resize');
          center_map(maps[i]);
        }
        $('.slider').slick('setPosition');
      });

      // Support hash navigation with tabs
      $('a').click(function(ev) {
        if(window.location.hash) {
          setTimeout(function() {
            var hash = window.location.hash.replace('#', '');
            $('*[data-content="'+hash+'"]').trigger('click');
          }, 0);
        } 
      });
      
      $(document).ready(function() {
        if(window.location.hash) {
          var hash = window.location.hash.replace('#', '');
          $('*[data-content="'+hash+'"]').trigger('click');
        }
      });


      // Select all links with hashes
      $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .not('[href="#!"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
          && 
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 400, function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });

      // Searchform
      $('.btn-search').click(function(){
        $('body').toggleClass('searching');
      });
      $('.close-search').click(function(){
        $('body').removeClass('searching');
      });


      // JS Back button
      $('.js-btn-back').click(function(e){
        e.preventDefault();
        window.history.back();
      });


      // Force footer to be height of children for slide draw effect
      // Height could be set in css but that would not allow client to change content.
      $(window).resize(function(){
        var height = $('.main-footer .primary-content').height() + $('.main-footer .signup').height();// + $('.main-footer .reveal-footer').height();
        $('.main-footer').height(height + 'px');
      });
      var height = $('.main-footer .primary-content').height() + $('.main-footer .signup').height();// + $('.main-footer .reveal-footer').height();
      $('.main-footer').height(height + 'px');

    }
  };


  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */

  var map = null;
  $(document).ready(function(){
    $('.acf-map').each(function(index, el){

      // create map
      map = new_map( $(this) );

    });

  });

  $(document).ready(function(){
    $( '.nav-primary li:has(ul)' ).doubleTapToGo();

    $( '.nav-primary.mobile .nav > li.menu-item-has-children' ).on('click', function(ev) {
      var element = $(this);
      $('.nav-primary.mobile .nav > li.menu-item-has-children').each(function(index) {
        if(!$(this).is(element)) {
          $(this).removeClass('active');
        }
      });
      ev.preventDefault();
      $(this).toggleClass('active');
    });

    $( '.nav-primary.mobile .nav > li.menu-item-has-children > ul li' ).on('click', function(ev) {
      ev.stopPropagation();
    });

  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Scroll to top function
  $(document).ready(function() {
    var backTop = document.getElementById('scrollTop'),
    offset = 300,
    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offsetOpacity = 1200,
    scrollDuration = 700,
    scrolling = false;

    if( backTop ) {
      //update back to top visibility on scrolling
      window.addEventListener("scroll", function(event) {
        if( !scrolling ) {
          scrolling = true;

          if( !window.requestAnimationFrame ) {
            setTimeout(checkBackToTop, 250);
          } else {
            window.requestAnimationFrame(checkBackToTop);
          }
        }
      });
      //smooth scroll to top
      backTop.addEventListener('click', function(event) {
        event.preventDefault();

        if( !window.requestAnimationFrame ) {
          window.scrollTo(0, 0);
        } else {
          scrollTop(scrollDuration);
        }
      });
    }

    function checkBackToTop() {
      var windowTop = window.scrollY || document.documentElement.scrollTop;
      if( windowTop > offset ) {
        addClass(backTop, 'show');
      } else {
        removeClass(backTop, 'show');
      }
      //( windowTop > offsetOpacity ) && addClass(backTop, 'cd-top--fade-out');
      scrolling = false;
    }
    
    function scrollTop(duration) {
        var start = window.scrollY || document.documentElement.scrollTop,
            currentTime = null;
            
        var animateScroll = function(timestamp){
          if (!currentTime) currentTime = timestamp;        
            var progress = timestamp - currentTime;
            var val = Math.max(Math.easeInOutQuad(progress, start, -start, duration), 0);
            window.scrollTo(0, val);
            if(progress < duration) {
                window.requestAnimationFrame(animateScroll);
            }
        };

        window.requestAnimationFrame(animateScroll);
    }

    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
    };

    //class manipulations - needed if classList is not supported
    function hasClass(el, className) {
        if (el.classList) return el.classList.contains(className);
        else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    }

    function addClass(el, className) {
      var classList = className.split(' ');
      if (el.classList) el.classList.add(classList[0]);
      else if (!hasClass(el, classList[0])) el.className += " " + classList[0];
      if (classList.length > 1) addClass(el, classList.slice(1).join(' '));
    }

    function removeClass(el, className) {
      var classList = className.split(' ');
        if (el.classList) el.classList.remove(classList[0]);  
        else if(hasClass(el, classList[0])) {
          var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
          el.className=el.className.replace(reg, ' ');
        }
        if (classList.length > 1) removeClass(el, classList.slice(1).join(' '));
    }
  });

})(jQuery); // Fully reference jQuery after this point.
